<template>
  <div class="sign-up">
    <p class="sign-title">Fill in the information:</p>
    <div class="sign-modal">
      <a-form-model :model="signForm" ref="signForm" :rules="modalRules">
        <a-form-model-item label="Country" prop="country">
          <a-select v-model="signForm.country" @change="handleChange">
            <a-select-option
              v-for="country in countryData"
              :key="country.regCode"
              :value="country.regCode"
            >
              {{ country.enName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="Company Name" prop="companyNameEn">
          <a-input v-model="signForm.companyNameEn" />
        </a-form-model-item>
        <a-form-model-item label="E-mail" prop="email">
          <a-input v-model="signForm.email" />
        </a-form-model-item>
        <a-form-model-item
          label="Mobile Phone number"
          class="phone"
          prop="phoneNumber"
        >
          <a-input
            :addon-before="signForm.areaCode ? '+' + signForm.areaCode : ''"
            v-model="signForm.phoneNumber"
          />
        </a-form-model-item>
        <a-row>
          <a-col :span="12">
            <a-form-model-item label="First Name" prop="firstName">
              <a-input v-model="signForm.firstName" />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item
              label="Last Name"
              style="margin-left: 30px"
              prop="lastName"
            >
              <a-input v-model="signForm.lastName" />
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </div>
    <p class="sign-title">Industry selection</p>
    <div class="classification">
      <a-checkbox-group v-model="checkedList">
        <a-checkbox
          v-for="check in checkData"
          :key="check.value"
          :value="check.value"
        >
          {{ check.label }}
        </a-checkbox>
      </a-checkbox-group>
    </div>
    <a-button class="sign-btn" @click="signClick" :loading="loading"
      >Sign up now</a-button
    >

    <!-- <p class="support-tit">Support Units：</p>
        <ul class="support-box">
          <li>
            <img
              src="http://image.matchupexpo.com/5e2e2ff849f0403588a87158dcb5cdd2.png"
              alt=""
            />
            <p class="bot-tit">
              Federação das Indústrias <br />
              do Estado de São Paulo
            </p>
          </li>
          <li>
            <img
              src="http://image.matchupexpo.com/bfa1956d1e2c404490eea7106776ef47.png"
              alt=""
            />
            <p class="bot-tit">
              Confederação das Associações <br />
              Comerciais e Empresariais do Brasil
            </p>
          </li>
          <li>
            <img
              src="http://image.matchupexpo.com/1fbabdf543e040338a8bf94d3ea4ff31.png"
              alt=""
            />
            <p class="bot-tit">Asociación de Exportadores</p>
          </li>
          <li>
            <img
              src="http://image.matchupexpo.com/8ad479310a51470c90eafe25099eeecf.png"
              alt=""
            />
            <p class="bot-tit">
              Los Socios de la Cámara <br />
              Nacional de Comercio
            </p>
          </li>
        </ul> -->
  </div>
</template>
<script>
import { getChannel } from "@/utils/auth";
export default {
  data() {
    // 邮箱
    let valiedateEmail = (rule, value, callback) => {
      const reg =
        /^([a-zA-Z0-9\S]{1,})+\@+(([a-zA-Z0-9\S]{1,})\.([a-zA-Z0-9\S]{1,})|([a-zA-Z0-9\S]{1,})\.([a-zA-Z0-9\S]{1,})+(\S){1,})$/;
      // const reg = /^[^@].*[^@]$/;
      if (!value) {
        callback(new Error("Please fill in the E-mail correctly"));
      } else if (!reg.test(value)) {
        callback(new Error("Please enter the correct email address"));
      } else {
        callback();
      }
    };
    return {
      signForm: {
        areaCode: "", //区号
        companyNameEn: undefined,
        country: undefined,
        exhibitionId: 263,
        exhibitionSignupType: 1,
        phoneNumber: undefined,
        requirePassword: false,
        firstName: undefined,
        lastName: undefined,
        email: undefined,
        goodsCategory: [],
        sourceCode: null,
      },
      // 验证规则
      modalRules: {
        email: [{ required: true, validator: valiedateEmail, trigger: "blur" }],
        country: [
          {
            required: true,
            message: "Please fill in the country",
            trigger: "blur",
          },
        ],
        companyNameEn: [
          {
            required: true,
            message: "Please fill in the companyNameEn",
            trigger: "blur",
          },
        ],
        phoneNumber: [
          {
            required: true,
            message: "Please fill in the phoneNumber",
            trigger: "blur",
          },
        ],
        firstName: [
          {
            required: true,
            message: "Please fill in the firstName",
            trigger: "blur",
          },
        ],
        lastName: [
          {
            required: true,
            message: "Please fill in the firstName",
            trigger: "blur",
          },
        ],
      },
      countryData: [
        {
          areaCode: "54",
          cnName: "阿根廷",
          enName: "Argentina",
          regCode: "G402",
        },
        {
          areaCode: "591",
          cnName: "玻利维亚",
          enName: "Bolivia",
          regCode: "G408",
        },
        {
          regCode: "G410",
          cnName: "巴西",
          enName: "Brazil",
          areaCode: "55",
        },
        {
          regCode: "G412",
          cnName: "智利",
          enName: "Chile",
          areaCode: "56",
        },
        {
          regCode: "G413",
          cnName: "哥伦比亚",
          enName: "Colombia",
          areaCode: "57",
        },
        {
          regCode: "G419",
          cnName: "厄瓜多尔",
          enName: "Ecuador",
          areaCode: "593",
        },
        {
          regCode: "G424",
          cnName: "圭亚那",
          enName: "Guyana",
          areaCode: "592",
        },
        {
          regCode: "G433",
          cnName: "巴拉圭",
          enName: "Paraguay",
          areaCode: "595",
        },
        {
          regCode: "G434",
          cnName: "秘鲁",
          enName: "Peru",
          areaCode: "51",
        },
        {
          regCode: "G441",
          cnName: "苏里南",
          enName: "Suriname",
          areaCode: "597",
        },
        {
          regCode: "G444",
          cnName: "乌拉圭",
          enName: "Uruguay",
          areaCode: "598",
        },
        {
          regCode: "G445",
          cnName: "委内瑞拉",
          enName: "Venezuela",
          areaCode: "58",
        },
      ],
      checkedList: [],
      checkData: [
        { label: " Smart Home", value: 538 },
        { label: " Electric & Lighting", value: 539 },
        { label: " Hardware & Building Materials", value: 540 },
        { label: " Auto & Motocycle Parts", value: 541 },
        { label: " Consumer Electronics", value: 542 },
        { label: " Textiles", value: 543 },
        { label: " Machinery & Accessory", value: 546 },
        { label: " Others", value: 547 },
      ],
      loading: false,
    };
  },
  methods: {
    handleChange(val) {
      this.countryData.forEach((item) => {
        if (item.regCode === val) {
          this.signForm.areaCode = item.areaCode;
        }
      });
    },
    signClick() {
      this.$refs.signForm.validate((valid) => {
        if (valid) {
          if (this.checkedList.length) {
            this.signForm.goodsCategory = this.checkedList.toString();
            this.signForm.sourceCode = getChannel();
            this.loading = true;
            this.$store
              .dispatch("userRegister", this.signForm)
              .then((data) => {
                this.signForm = Object.assign(this.signForm, {
                  areaCode: " ", //区号
                  companyNameEn: undefined,
                  country: undefined,
                  exhibitionId: 263,
                  exhibitionSignupType: 1,
                  phoneNumber: undefined,
                  requirePassword: false,
                  firstName: undefined,
                  lastName: undefined,
                  email: undefined,
                  goodsCategory: [],
                  sourceCode: null,
                });
                this.checkedList = [];
                this.loading = false;
                this.$emit("isShowSuccessfully", data.data.email);
              })
              .catch(() => {
                this.loading = false;
              });
          } else {
            this.$message.error("Please select category");
          }
        }
      });
    },
    // onChange(check) {
    //   console.log(check);
    // },
  },
  created() {},
};
</script>
<style lang="scss" scoped>
/deep/.ant-btn {
  border: none;
}
.sign-up {
  width: 1200px;
  margin: 0 auto;
  color: #fff;
  padding-top: 30px;
  .sign-title {
    font-size: 24px;
    font-weight: bold;
    padding-left: 35px;
  }
  .sign-modal {
    padding: 30px 45px;
    background: rgba(77, 162, 217, 0.17);
    border: 2px solid #52abe5;
    border-radius: 10px;
    margin: 15px 0 50px 0;
    /deep/.ant-form-item-label > label {
      color: #fff;
      font-size: 18px;
    }
    /deep/.ant-select-selection--single {
      height: 55px;
      background: #02237a;
      border: none;
      color: #fff;
    }
    /deep/.ant-select-selection__rendered {
      line-height: 55px;
    }
    /deep/.ant-select-arrow {
      color: #fff;
      font-size: 20px;
    }
    /deep/.ant-input {
      height: 55px;
      background: #02237a;
      border: none;
      color: #fff;
    }
    .phone {
      /deep/.ant-input {
        height: 55px;
        background: #02237a;
        border: 2px solid #09c9f2;
        color: #fff;
      }
      /deep/.ant-input-group-addon {
        background: #02237a;
        border: 2px solid #09c9f2;
        border-right: none;
        color: #fff;
        width: 80px;
        font-size: 18px;
      }
      /deep/.ant-form-item-label > label {
        color: #09c9f2;
        font-size: 18px;
      }
    }
  }
  .classification {
    // height: 270px;
    padding: 35px 80px;
    background: rgba(77, 162, 217, 0.17);
    border: 2px solid #52abe5;
    border-radius: 10px;
    margin: 40px 0;
    /deep/.ant-checkbox-group {
      width: 100%;
    }
    /deep/.ant-checkbox-wrapper {
      width: 50%;
      color: #fff;
      margin: 5px 0;
    }
  }
  .sign-btn {
    width: 500px;
    height: 50px;
    line-height: 50px;
    background: #09c9f2;
    border-radius: 25px;
    margin: 0 auto;
    text-align: center;
    font-size: 22px;
    cursor: pointer;
    color: #fff;
    margin-left: 340px;
  }
  .support-tit {
    font-size: 20px;
    margin: 65px 0 40px;
    padding-left: 80px;
  }
  .support-box {
    display: flex;
    flex-wrap: wrap;
    width: 900px;
    margin: 0 auto;
    justify-content: space-between;
    li {
      width: 50%;
      display: flex;
      align-items: center;
      margin-bottom: 40px;
      .bot-tit {
        // max-width: 170px;
        margin-left: 15px;
      }
    }
  }
}
</style>
